import { Platform } from 'react-native'
type VercelConstants = {
  // isVercel?: string
  vercelUrl?: string
  vercelGitCommitRef?: string
  vercelGitCommitSha?: string
  vercelGitCommitAuthorName?: string
  vercelGitCommitMessage?: string
}

export default function vercelConstants(): VercelConstants {
  if (Platform.OS !== 'web') {
    return {}
  }
  if (process.env.NODE_ENV !== 'production') {
    return {}
  }

  return {
    // isVercel: process.env.NEXT_PUBLIC_VERCEL,
    vercelUrl: process.env.NEXT_PUBLIC_VERCEL_URL,
    vercelGitCommitRef: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF,
    vercelGitCommitSha: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
    vercelGitCommitAuthorName:
      process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_AUTHOR_NAME,
    vercelGitCommitMessage: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_MESSAGE,
  }
}
