import { AllUserAccountTypes, Artist } from '@beatgig/api-services'
import {
  ArtistSmall,
  ArtistSmallDisplay,
  ArtistsService,
} from '@beatgig/api-services/artist'
import { ReferralsService } from '@beatgig/api-services/referrals'
import {
  GetUser,
  UserRole,
  UserActionsService,
  UserDisplay,
  SellerUserType,
  AdminUserType,
  BuyerUserType,
  UserExists,
  UsersService,
} from '@beatgig/api-services/user'
import { Unpromisify } from '@beatgig/helpers/types'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { mutate } from 'swr'
import { Union } from 'ts-toolbelt'
import { renameRequestBody, RenameRequestBody } from './types'

export class User {
  /**
   * Determine if this user is a buyer.
   *
   * This function adds TypeScript support to a generic user document from the backend.
   *
   * @param user The user object
   */
  static isBuyer(
    user?: GetUser | null
  ): user is Omit<UserDisplay, 'user_role'> & {
    user_role: UserRole.BUYER
  } {
    return !!(user && (user as UserDisplay)?.user_role === UserRole.BUYER)
  }
  // static isBuyer(
  //   user?: GetUser | null
  // ): user is Omit<BuyerUserAccount, 'user_role' | 'user_type'> & {
  //   user_role: UserRole.BUYER
  //   user_type: BuyerUserType
  // } {
  //   return !!(user && (user as BuyerUserAccount)?.user_role === UserRole.BUYER)
  // }
  /**
   * Determine if this user is a seller.
   *
   * This function adds TypeScript support to a generic user document from the backend.
   *
   * @param user The user object
   */
  static isSeller(
    user?: GetUser | null
  ): user is Omit<UserDisplay, 'user_role'> & {
    user_role: UserRole.SELLER
  } {
    return !!(user && (user as UserDisplay)?.user_role === UserRole.SELLER)
  }
  /**
   * Determine if this user is an admin.
   *
   * This function adds TypeScript support to a generic user document from the backend.
   *
   * @param user The user object
   */
  static isAdmin(
    user?: GetUser | null
  ): user is Omit<UserDisplay, 'user_role'> & {
    user_role: UserRole.ADMIN
  } {
    return !!(user && (user as UserDisplay)?.user_role === UserRole.ADMIN)
  }
  /**
   * Determine if this user is an owner. Owners have full permissions.
   *
   * This function adds TypeScript support to a generic user document from the backend.
   *
   * @param user The user object
   */
  static isOwner(
    user?: GetUser | null
  ): user is Omit<UserDisplay, 'user_role'> & {
    user_role: UserRole.ADMIN
    user_type: AdminUserType.OWNER
  } {
    return !!(
      user &&
      (user as UserDisplay)?.user_role === UserRole.ADMIN &&
      (user as UserDisplay)?.admin_type === AdminUserType.OWNER
    )
  }
  /**
   * TODO omit?
   */
  static isMultiArtistSeller(
    user?: GetUser | null
  ): user is Omit<UserDisplay, 'user_role'> & {
    user_role: UserRole.SELLER
    metadata: Omit<UserDisplay['metadata'], 'user_type'> & {
      user_type:
        | SellerUserType.AGENT
        | SellerUserType.MANAGER
        | SellerUserType.OTHER_SELLER
    }
  } {
    if (user && (user as UserDisplay).user_role === UserRole.SELLER) {
      return (
        (user as UserDisplay).metadata.user_type === SellerUserType.AGENT ||
        (user as UserDisplay).metadata.user_type === SellerUserType.MANAGER ||
        (user as UserDisplay).metadata.user_type === SellerUserType.OTHER_SELLER
      )
    }
    return false
  }
  /**
   * @deprecated
   */
  static isGreekBuyer(
    user?: GetUser | null
  ): user is Omit<UserDisplay, 'user_role'> & {
    user_role: UserRole.BUYER
    metadata: Omit<UserDisplay['metadata'], 'user_type'> & {
      user_type: BuyerUserType.FRATERNITY_SORORITY
    }
  } {
    if (user && (user as UserDisplay).user_role === UserRole.BUYER) {
      return !!(
        (user as UserDisplay).metadata.user_type ===
        BuyerUserType.FRATERNITY_SORORITY
      )
    }
    return false
  }
  static isUPBBuyer(
    user?: GetUser | null
  ): user is Omit<UserDisplay, 'user_role'> & {
    user_role: UserRole.BUYER
    metadata: Omit<UserDisplay['metadata'], 'user_type'> & {
      user_type: BuyerUserType.UNIVERSITY_PROGRAM_BOARD
    }
  } {
    if (user && (user as UserDisplay).user_role === UserRole.BUYER) {
      return (
        (user as UserDisplay).metadata.user_type ===
        BuyerUserType.UNIVERSITY_PROGRAM_BOARD
      )
    }
    return false
  }
  static isMultiVenueBuyer(
    user?: GetUser | null
  ): user is Omit<UserDisplay, 'user_role'> & {
    user_role: UserRole.BUYER
    metadata: Omit<UserDisplay['metadata'], 'user_type'> & {
      user_type:
        | BuyerUserType.NIGHTCLUB
        | BuyerUserType.RESTAURANT
        | BuyerUserType.BAR
        | BuyerUserType.BREWERY
        | BuyerUserType.COUNTRY_CLUB
        | BuyerUserType.MUNICIPALITY
        | BuyerUserType.THEATER
        | BuyerUserType.VINEYARD_WINERY
    }
  } {
    const theUser = user as UserDisplay
    if (user && theUser.user_role === UserRole.BUYER) {
      return (
        theUser.metadata.user_type === BuyerUserType.NIGHTCLUB ||
        theUser.metadata.user_type === BuyerUserType.RESTAURANT ||
        theUser.metadata.user_type === BuyerUserType.BAR ||
        theUser.metadata.user_type === BuyerUserType.BREWERY ||
        theUser.metadata.user_type === BuyerUserType.COUNTRY_CLUB ||
        theUser.metadata.user_type === BuyerUserType.MUNICIPALITY ||
        theUser.metadata.user_type === BuyerUserType.THEATER ||
        theUser.metadata.user_type === BuyerUserType.VINEYARD_WINERY
      )
    }
    return false
  }
  static hasOnboarded(
    user?: GetUser | null
  ): user is Union.Filter<GetUser, UserExists> {
    return !!(
      user &&
      (user as Union.Filter<GetUser, UserExists>)?.id &&
      (user as UserExists)?.user_exists !== false
    )
  }
  static isApproved(
    user?: GetUser | null
  ): user is Union.Filter<GetUser, UserExists> & {
    approved: true
  } {
    return !!(
      user &&
      (user as UserExists)?.user_exists !== false &&
      (user as Union.Filter<GetUser, UserExists>).approved
    )
  }
  // static create(
  //   props: Required<RenameRequestBody<typeof UsersService.createUser, 'user'>>
  // ) {
  //   const { user, ...rest } = props
  //   return UsersService.createUser({
  //     requestBody: user,
  //     ...rest,
  //   })
  // }
  static create = renameRequestBody(UsersService.createUser, 'user')
  static swrKey(uid: string): [string, string] {
    return [uid, `user`]
  }
  static mutateInSWRCache(uid: string, user: GetUser) {
    return mutate(User.swrKey(uid), user, false)
  }
  static get(uid: string) {
    // no-op
  }
  static me() {
    return UsersService.getUser()
  }
  static edit(id: string, user: AllUserAccountTypes) {
    // return AuthService.user
  }
  static getMyArtists() {
    return ArtistsService.getMyArtists({})
  }
  static myArtistsSWRKey() {
    return ['my-artists']
  }
  static revalidateMyArtistsSWR() {
    return mutate(User.myArtistsSWRKey())
  }
  static myLikedArtistIdsSWRKey(uid: string) {
    if (!uid) return null
    return [uid, 'liked-artist-ids']
  }
  static myLikedArtistsSWRKey(
    uid: string
    // { limit, lastDocId }: { limit?: number; lastDocId?: string }
  ) {
    type Key = [
      key: 'liked-artists',
      uid: string
      // limit?: number,
      // lastDocId?: string
    ]
    // const key: Key = ['liked-artists', uid, limit, lastDocId]
    return [uid, 'liked-artists']
  }

  static getMyViewedArtists({ limit }: { limit: number }) {
    // return UserActionsService.
  }
  static viewArtist(artistId: string) {
    return UserActionsService.viewArtist({ artistId })
  }
  static likeArtist(
    // artist: Artist | SmallArtist,
    { id }: { id: string },
    { unlike = false }: { unlike: boolean }
  ) {
    return UserActionsService.likeArtist({ artistId: id, unlike })
  }
  static haveILikedArtist({
    artistId,
    likedIds,
  }: {
    artistId?: string
    likedIds?: Unpromisify<ReturnType<typeof User.getMyLikedArtistIds>> | null
  }) {
    return !!(artistId && likedIds?.includes(artistId))
    // return !!(artistId && likedIds?.has(artistId))
  }
  /**
   * Similar to `addArtistLikeToCache`, except it only takes an artist id.
   *
   * This is used when we can't access the entire artist object when we like (such as when we like from a long memoized liked)
   *
   * Used with `useLikeArtistMemo`.
   */
  static addArtistLikeIDToCache(
    { artistId }: { artistId: string },
    { unlike = false, uid }: { unlike: boolean; uid?: string }
  ) {
    type LikedIds = Unpromisify<ReturnType<typeof User.getMyLikedArtistIds>>

    // const artistId = artist?.id
    if (!uid || !artistId) return null

    return mutate(
      User.myLikedArtistIdsSWRKey(uid),
      // (currentlyLikedArtistIds: LikedIds) => {
      //   const newLikes = new Set(currentlyLikedArtistIds)
      //   if (unlike) {
      //     newLikes.delete(artistId)
      //   } else {
      //     newLikes.add(artistId)
      //   }
      //   console.log('[add-artist-like-to-cache] new cache:', newLikes)
      //   return newLikes
      // },
      (currentlyLikedArtistIds: LikedIds = []) => {
        if (unlike) {
          // update the actual cache with the small artist objects
          mutate(
            User.myLikedArtistsSWRKey(uid),
            (currentlyLikedArtists: ArtistSmall[] = []) => {
              return currentlyLikedArtists.filter(
                (artist) => artist?.id !== artistId
              )
            },
            false
          )
          // also, remove this item from the IDs only cache
          return currentlyLikedArtistIds.filter((id) => id !== artistId)
        } else {
          // update the actual cache with small artist objects
          // can't do this without the object
          // mutate(
          //   User.myLikedArtistsSWRKey(uid),
          //   (currentlyLikedArtists: SmallArtist[] = []) => {
          //     return [artist, ...currentlyLikedArtists]
          //   },
          //   false
          // )
          // to-do, we need the whole artist object here to add to small artist cache.
          return [artistId, ...currentlyLikedArtistIds]
        }
        // console.log('[add-artist-like-to-cache] new cache:', newLikes)
        // return newLikes
      },
      false
    )
  }
  static addArtistLikeToCache(
    artist: Artist | ArtistSmallDisplay | ArtistSmall,
    { unlike = false, uid }: { unlike: boolean; uid?: string }
  ) {
    type LikedIds = Unpromisify<ReturnType<typeof User.getMyLikedArtistIds>>

    const artistId = artist?.id
    if (!uid || !artistId) return null

    return mutate(
      User.myLikedArtistIdsSWRKey(uid),
      // (currentlyLikedArtistIds: LikedIds) => {
      //   const newLikes = new Set(currentlyLikedArtistIds)
      //   if (unlike) {
      //     newLikes.delete(artistId)
      //   } else {
      //     newLikes.add(artistId)
      //   }
      //   console.log('[add-artist-like-to-cache] new cache:', newLikes)
      //   return newLikes
      // },
      (currentlyLikedArtistIds: LikedIds = []) => {
        if (unlike) {
          // update the actual cache with the small artist objects
          mutate(
            User.myLikedArtistsSWRKey(uid),
            (currentlyLikedArtists: ArtistSmall[] = []) => {
              return currentlyLikedArtists.filter(
                (artist) => artist?.id !== artistId
              )
            },
            false
          )
          // also, remove this item from the IDs only cache
          return currentlyLikedArtistIds.filter((id) => id !== artistId)
        } else {
          // update the actual cache with small artist objects
          mutate(
            User.myLikedArtistsSWRKey(uid),
            (currentlyLikedArtists: ArtistSmall[] = []) => {
              return [artist, ...currentlyLikedArtists]
            },
            false
          )
          // to-do, we need the whole artist object here to add to small artist cache.
          return [artistId, ...currentlyLikedArtistIds]
        }
        // console.log('[add-artist-like-to-cache] new cache:', newLikes)
        // return newLikes
      },
      false
    )
  }
  static async getMyLikedArtistIds({ limit }: { limit?: number } = {}) {
    console.log('[user.get-my-liked-artist-ids] calling')
    const likedIds = await UserActionsService.getLikedArtistsIds({
      limit,
    })
    return likedIds
    // return new Set(likedIds)
  }
  static getMyLikedArtists = UserActionsService.getLikedArtists
  static async refetchMyLikedArtistsSWR(uid: string) {
    if (!uid) return
    return mutate(User.myLikedArtistsSWRKey(uid))
  }
  static async refetchMyLikedArtistIdsSWR(uid: string) {
    if (!uid) return
    return mutate(User.myLikedArtistIdsSWRKey(uid))
  }
  static async editProfileImage(
    image: Parameters<
      typeof UsersService['updateProfileImage']
    >[0]['requestBody']
  ) {
    return UsersService.updateProfileImage({
      requestBody: image,
    })
  }
  static asyncStorageKey = 'user-account'
  static async addToAsyncStorage(user: AllUserAccountTypes) {
    if (!user) return null
    return AsyncStorage.setItem(this.asyncStorageKey, JSON.stringify(user))
  }
  static async getFromAsyncStorage() {
    const user = await AsyncStorage.getItem(this.asyncStorageKey)
    if (user) {
      return JSON.parse(user) as AllUserAccountTypes
    }
    return null
  }
  static async removeFromAsyncStorage() {
    return AsyncStorage.removeItem(this.asyncStorageKey)
  }
  static needsToOnboard(user?: GetUser | null): user is UserExists {
    return !!(user && (user as UserExists).user_exists === false)
  }
  static getPublicUser({ slug: userSlug }: { slug: string }) {
    return ReferralsService.getPublicUserFromSlug({ userSlug })
  }
  static publicUserSWRKey({
    slug,
  }: Parameters<typeof User['getPublicUser']>[0]) {
    type Key = [string, 'public-user']

    const key: Key = [slug, 'public-user']

    return key
  }
}
