import { useAuthGate } from 'react-doorman'

export default function useAuth() {
  // return {
  //   loading: false,
  //   user: null
  // } as ReturnType<typeof useAuthGate>
  //  TODO fix!!!
  return useAuthGate()
}
