import Constants from 'expo-constants'
import versionNumber from '@beatgig/helpers/version'
import vercelConstants from '@beatgig/constants/vercel-constants'

export default class AnalyticsBase {
  static track(event: string) {
    if (typeof window !== 'undefined' && window?.analytics) {
      window.analytics.track(
        event,
        {
          sessionId: Constants.sessionId,
        },
        {
          context: {
            versionNumber,
            ...vercelConstants(),
          },
        }
      )
    }
  }
  static screen(name: string) {
    if (typeof window !== 'undefined' && window?.analytics) {
      window.analytics.page(
        name,
        {
          sessionId: Constants.sessionId,
        },
        {
          context: {
            versionNumber,
            ...vercelConstants(),
          },
        }
      )
    }
  }
  static screenWithProperties(
    name: string,
    properties: Record<string, unknown> = {}
  ) {
    if (typeof window !== 'undefined' && window?.analytics) {
      window.analytics.page(
        name,
        {
          ...properties,
          sessionId: Constants.sessionId,
        },
        {
          context: {
            versionNumber,
            ...vercelConstants(),
          },
        }
      )
    }
  }
  static trackWithProperties(
    event: string,
    properties: Record<string, unknown> = {}
  ) {
    if (typeof window !== 'undefined' && window?.analytics) {
      window.analytics.track(
        event,
        {
          ...properties,
          sessionId: Constants.sessionId,
        },
        {
          context: {
            versionNumber,
            ...vercelConstants(),
          },
        }
      )
    }
  }
  static identify({
    uid,
    name,
    phone_number,
  }: {
    uid: string
    name: string
    phone_number: string
  }) {
    if (typeof window !== 'undefined' && window?.analytics) {
      window.analytics.identify(
        uid,
        {
          name,
          phone_number,
          sessionId: Constants.sessionId,
        },
        {
          context: {
            versionNumber,
            ...vercelConstants(),
          },
        }
      )
    }
  }
  static reset() {
    if (typeof window !== 'undefined' && window?.analytics) {
      window.analytics.reset()
    }
  }
}
