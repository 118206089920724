import type { Rename } from '@beatgig/helpers/types/rename'
import type { Function } from 'ts-toolbelt'

export type FirstArg<T extends (t: any) => any> = Parameters<T>[0]

export type RequestBody<
  T extends ({ requestBody: any }) => any
> = FirstArg<T>['requestBody']

export type RenameRequestBody<
  F extends (t: any) => any,
  NewName extends string
> = Rename<Parameters<F>[0], 'requestBody', NewName>

type NotKey<T extends object, Path> = Path extends keyof T ? never : string

export function renameRequestBody<
  F extends (t: { requestBody: any }) => any,
  P extends string,
  Path extends NotKey<Parameters<F>[0], P>
>(func: F, key: Path): (props: RenameRequestBody<F, Path>) => ReturnType<F> {
  return ({ [key]: requestBody, ...props }: RenameRequestBody<F, Path>) => {
    return func({
      ...props,
      requestBody,
    })
  }
}
